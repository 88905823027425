// eslint-disable-next-line import/extensions
import { sroStyle } from './styles';

export type CarouselType = 'carousel' | 'gallery';
export interface BrickCarouselData {
  children: NodeListOf<HTMLElement>;
  minSlidesToShow?: string;
  type?: CarouselType;
  hideBtnStartEnd?: boolean;
}

// Get the number of articles, so we can set correct column number for the grid.
export function numberOfArticles(children) {
  const articlesLength = children.length;
  return articlesLength;
}

export function carouselTemplate(data: BrickCarouselData) {
  if (!data) {
    return '';
  }

  const { children } = data;

  const markup = /* html */ `
  <a data-static href="#skip-" class="${sroStyle}">Hopp over karusellen.</a>
  <section data-static role="region" class="carousel" aria-label="Innholdskarusell." aria-describedby="carousel-title-">
    <p data-static class="${sroStyle}" id="carousel-title-">Innholdskarusell med ${numberOfArticles(
      children
    )} artikler.</p>
    <ul data-static aria-label="Navigasjon for innholdskarusell" class="carousel-nav">
      <li>
        <button type="button" class="prev-btn content-slider-btn" data-content-slider-btn aria-label="Forrige artikkel." disabled>
        <div><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width="20" height="20" fill="currentColor" class="arrow-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/></svg></div></button>
      </li>
      <li>
        <button type="button" class="next-btn content-slider-btn" data-content-slider-btn aria-label="Neste artikkel.">
          <div><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width="20" height="20" fill="currentColor" class="arrow-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/></svg></div>
        </button>
      </li>
    </ul>
    <ul data-static class="contents-wrapper" data-contents-wrapper tabindex="0"></ul>
  </section>
  <div data-static id="skip-" data-target="skip" class="${sroStyle}" tabindex="-1"></div>
  `;

  return markup;
}
